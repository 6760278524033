body {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
}
body, h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    font-weight: 400;
}
.App .container-fluid{
    padding-left: 2rem;
    padding-right: 2rem;
}
.main-nav .navbar-brand{
    margin-right: 10rem;
}
.main-nav .navbar-nav .nav-item{
    margin-right: 3rem;
}
.main-nav .navbar-brand::first-letter {
    color: #dc3545;
    font-size: 35px;
}
.hero-container{
    padding: 100px 80px;
}
.home-section{
    padding: 50px 0;
}
.home-data-services{
    background-color: #f8f9fa;
}
.btn-container .btn{
    margin-top: 1rem;
    margin-right: 2rem;
}
.Home .navbar{
    padding: 0;
    height: 62px;
}
footer{
    background-color: #222;
    color: #f0f0f0;
    padding: 50px 0 10px 0;
}
.copyright {
    text-align: center;
    border-top: 1px solid #f8f9fa;
    padding: 20px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 25px;
}
.footer-navbar a, address, .footer-navbar1 a {
    text-decoration: none;
    color: gray;
    font-size: 14px;
    display: block;
    transition: all 0.3s ease-in-out;
    padding-bottom: 5px;
}
.footer-navbar1 a{
    display: inline-block;
    margin-right: 1rem;
}
.footer-navbar a:hover, .footer-navbar1 a:hover {
    color: #fff;
    text-decoration: none;
}
.form-container{
    margin: 50px auto;
    width: 450px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.49);
    border: none;
    border-radius: 2px;
}
.form-header{
    padding: 15px;
    border-radius: 5px 5px 0 0;
    background-color: #f8f9f0;
}
.form-header h5{
    margin-bottom: 0;
}
.form-content{
    padding: 25px 35px;
}
.form-content .btn{
    display: block;
    width: 50%;
    margin: 20px auto;
    text-transform: uppercase;
    font-weight: 700;
}
.account-container .btn{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}
.form-links a{
    font-size: 14px;
}
.form-links .right-link{
    float: right;
}
.Header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
}
header{
    height: 62px;
    overflow: hidden;
}
.sidebar{
    width: 210px;
    height: 100%;
    position: fixed !important;
    left: 0px;
    overflow: auto;
    top: 45px;
    z-index: 3000;
    background: #e8e8e8;
}
.Console .navbar-expand-lg{
    margin-left: 0px;
    min-height: 45px;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.1);
}
.Console .navbar-conatainer {
    position: fixed;
    right: 0;
    left: 0;
    top:0;
    z-index: 1030;
    margin-bottom: 0;
    background: #fff;
}
.main-contents{
    margin-left: 210px;
    padding-top: 60px;
}
.nav-dropdown li i{
    margin-right: 10px;
    font-size: 18px;
}
.side-nav {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.side-nav li{
    line-height: 20px;
}
.side-nav li a{
    border-bottom: #56060e 2px solid;
    background: #721c24;
    background: linear-gradient(to bottom, #dc3545 0%, #721c24 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#555957', endColorstr='#3e4240', GradientType=0 );
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset;
    color: #eee;
    display: block;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    padding-left: 10px;
    font-size: 12px;
    font-weight: bold;
}
.side-nav li a span i{
    margin-right: 10px;
    font-size: 18px;
}
.side-nav li a:hover {
    border-bottom: #56060e 2px solid;
    background: #eee;
    color: #721c24;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
}
.main {
    margin: 0px 15px;
}
.main .breadcrumb{
    font-size: 14px;
    padding: 10px 15px;
    background: #e8e8e8;
}
.main .breadcrumb-item a{
    color: #dc3545;
}
.box{
    box-shadow:#dc3545 0px 0px 5px 0.5px;
    min-height: 150px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0.95);
    transition: all 0.3s ease;
}
.box:hover{
    transform: scale(1);
}
.box span i{
    font-size: 45px;
    color: #dc3545;
    transition: all 0.5s ease;
    transform: scale(1);
    margin-bottom: 10px;
}
.box h1{
    font-size: 20px;
    color: #181818;
    margin: 0;
    font-weight: 500;
}
.box:hover h1{
    color: #dc3545;
}
.box:hover span i{
    color: #181818;
    transform: scale(0.50);
}
.boxes:hover a{
    text-decoration: none;
}
.boxes{
    margin: 30px -15px;
}